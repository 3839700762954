import React, { Component } from 'react'
import { Container, Row, Col, Alert } from 'reactstrap'
import { Layout } from 'core/components'
import { ABanner, AAnimate } from 'shared/components/common'
import headImg from 'img/camion-para-banner-compratuboleto.png'

import { StatusRun } from 'services/runstatusService'
import moment from 'moment'
import { ApiErrors } from 'core/services/ApiService/types'
import Atravelstatus from 'modules/travel/components/TravelStatus/Atravelstatus'
import { navigate } from 'gatsby'

const DATE_FORMAT = 'L hh:mm A'

const STATUS: Status = {
  canceled: 'Cancelado',
  scheduled: 'Calendarizado',
  loading: 'Embarcando',
  'ready-to-go': 'Listo para salir',
  'in-transit': 'En transito',
  stopped: 'Detenido',
  downloading: 'Desembarcando',
  'ready-to-load': 'Listo para embarque',
  paused: 'Pausado',
  'finished-ok': 'Finalizado'
}

interface Status extends IndexSignature<string> {}

interface StateTravelProps {
  dates?: {
    arrival_date: string
    destination_status: string
    destiny_city_name: string
    origin_city_name: string
    travel_date: string
    type_route: string
    vehicle_number: string
  }
  mounted: boolean
  errorMessage?: string
  traveltype?: boolean
  parameters?: string
}

class StateTravel extends Component<PageProps, StateTravelProps> {
  state: StateTravelProps = {
    mounted: false,
    traveltype: true,
    parameters: ''
  }

  componentDidMount() {
    this.setState({ errorMessage: '' })
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
    const {
      location: { state }
    } = this.props
    if (state && state.ticketnumber) {
      this.getStatusRun(state.ticketnumber)
    }
    if (this.props.location.search) {
      this.state.parameters = this.props.location.search.substring(6, this.props.location.search.length)
      this.getStatusRun(this.state.parameters)
    }
  }

  StatusRunNavigate = (ticket: any) => {
    this.getStatusRun(ticket)
    navigate(`${this.props.location.pathname}/?code=${ticket}`)
  }

  getStatusRun = (ticket: any) => {
    StatusRun.getStatusTravel(ticket)
      .then((R: any) => {
        if (R.length > 1) {
          this.setState({ traveltype: false })
        } else {
          this.setState({ traveltype: true })
        }
        this.setState({ dates: R[0], errorMessage: '' })
      })
      .catch(err => {
        const newState: any = {}
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.errorMessage = err.message
        } else {
          newState.errorMessage = 'No se pudo consultar el estatus de corrida'
        }
        this.setState(newState)
      })
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { dates, mounted, errorMessage, traveltype } = this.state
    const statusValue = dates && dates.destination_status
    const statusText = (statusValue && STATUS[statusValue]) || 'Desconocido'
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ABanner
            title={'Estatus de corrida'}
            titleSize={9}
            titleClassName="big-white"
            content={<img className="img-fluid" src={headImg} />}
          />
          <Container className="pad">
            <Row className="mb-5">
              <Col sm={12}>
                <h5 className="big-gray">Para obtener información de tu viaje, proporciona el código de tu reservación.</h5>
              </Col>
            </Row>
            <Row>
              {dates ? (
                <Col sm={8}>
                  <Row>
                    <Col sm={4} xs={12}>
                      <h5 className="big-gray">Número de autobus:</h5>
                      <h4 className="big-blue">{dates.vehicle_number}</h4>
                      <br />
                    </Col>
                    <Col sm={4} xs={12}>
                      <h5 className="big-gray">Origen:</h5>
                      <h4 className="big-blue">{dates.origin_city_name}</h4>
                    </Col>
                    <Col sm={4} xs={12}>
                      <h5 className="big-gray">Destino:</h5>
                      <h4 className="big-blue">{dates.destiny_city_name}</h4>
                      <br />
                    </Col>
                    <Col sm={4} xs={12}>
                      <h5 className="big-gray">Tipo de viaje:</h5>
                      <h5 className="big-blue">{traveltype ? 'Sencillo' : 'Redondo'}</h5>
                      <br />
                    </Col>
                    <Col sm={4} xs={12}>
                      <h5 className="big-gray">Fecha de salida:</h5>
                      <h5 className="big-blue">{moment.utc(dates.travel_date).format(DATE_FORMAT)}</h5>
                    </Col>
                    <Col sm={4} xs={12}>
                      <h5 className="big-gray">Fecha de llegada:</h5>
                      <h5 className="big-blue">{moment.utc(dates.arrival_date).format(DATE_FORMAT)}</h5>
                    </Col>
                    <Col sm={12}>
                      <h5 className="big-gray">Estatus</h5>
                      <h4 className={dates.destination_status === 'scheduled' ? 'big-gray' : 'big-green'}>
                        <b>{statusText}</b>
                      </h4>
                      <br />
                      <Row>
                        <Col className="col-sm-12">
                          <h5 className="big-gray">Observaciones:</h5>
                          <h5 className="big-gray">Sin observaciones</h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : null}
              <Col sm={{ size: 4, offset: dates ? 0 : 4 }} className="p-0">
                {errorMessage ? (
                  <Alert color="danger" className="d-inline-block">
                    {errorMessage}
                  </Alert>
                ) : null}
                <Atravelstatus onConfirm={this.StatusRunNavigate} />
              </Col>
            </Row>
          </Container>
        </AAnimate>
      </Layout>
    )
  }
}

export default StateTravel
